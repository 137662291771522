<template>
  <div>
    <b-form-group
      label="Cari Hesap Tanımı"
      label-for="content"
    >
      <validation-provider
        #default="{ errors }"
        name="Cari Hesap Tanımı"
        rules="required"
      >
        <b-form-input
          id="content"
          v-model="dataItem.content"
          placeholder="Cari Hesap Tanımı"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'TitleContent',
  components: {
    BFormInput, BFormGroup, ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['currentAccounts/dataItem']
    },
  },
}
</script>

<template>
  <div>
    <b-card title="Yeni Hesabı Düzenle">
      <validation-observer ref="simpleRules">
        <account-form />
      </validation-observer>
    </b-card>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          :disabled="dataItem.submitButton"
          @click="submitForm"
        >
          <feather-icon icon="SaveIcon" />
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AccountForm from '@/views/CurrentAccounts/Components/AccountForm.vue'

export default {
  name: 'Add',
  components: {
    ValidationObserver,
    AccountForm,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  computed: {
    dataItem() {
      return this.$store.getters['currentAccounts/dataItem']
    },
    saveData() {
      return this.$store.getters['currentAccounts/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push(`/current-accounts/view/${val.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.dataItem.submitButton = false
    },
  },
  created() {
    localize('tr')
    this.$store.commit('currentAccounts/RESET_DATA_ITEM')
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('currentAccounts/getDataItem', this.$route.params.id)
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitButton = true
          this.$store.dispatch('currentAccounts/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>

<template>
  <div>
    <b-row>
      <b-col cols="12">
        <title-text />
      </b-col>
      <b-col cols="12">
        <account-no />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import AccountNo from '@/views/CurrentAccounts/Elements/AccountNo.vue'
import TitleText from '@/views/CurrentAccounts/Elements/Title.vue'

export default {
  name: 'AccountForm',
  components: {
    TitleText,
    AccountNo,
    BRow,
    BCol,
  },

}
</script>

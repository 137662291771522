<template>
  <div>
    <b-form-group
      label="Cari Hesap No"
      label-for="cnumber"
    >
      <b-form-input
        id="cnumber"
        v-model="dataItem.cnumber"
        placeholder="Cari Hesap No"
      />
    </b-form-group>
    <b-alert show>
      <div class="alert-body">
        <FeatherIcon icon="InfoIcon" />
        Cari hesap numarası boş bırakılırsa, sistem tarafından otomatik olarak oluşturulacaktır.
      </div>
    </b-alert>
  </div>
</template>
<script>
import { BFormGroup, BFormInput, BAlert } from 'bootstrap-vue'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'AccountNo',
  components: {
    BFormInput,
    BFormGroup,
    BAlert,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['currentAccounts/dataItem']
    },
  },
}
</script>
